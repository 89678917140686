import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() url: string;
  url$: Subscription;
  constructor(
    private ms: MenuService,
    private mc: MenuController) { }

  ngOnInit() {
    this.url$ = this.ms.currentRoute$.subscribe(url => {
      this.url = url;
    });
  }

  ngOnDestroy() {
    if (this.url$) {
      this.url$.unsubscribe();
    }
  }

  onClick() {
    this.mc.close('princ');
  }
}
