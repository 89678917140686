import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token.service';
import { Token } from 'src/app/models/token';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(private r: Router, private ts: TokenService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const check = route.pathFromRoot.join('/');
      const session = localStorage.getItem('_session') || 'loggedout';
      if (session === 'loggedout') {
        this.r.navigate(['login']);
        resolve(false);
      } else {
        this.ts.single(session).subscribe({
          next(ris) {
            this.tkn = ris;
            if (! ris || ris.deleted) {
              this.r.navigate(['login']);
            }
            resolve(! ris.deleted);
          },
          error(err) {
            this.r.navigate(['login']);
            resolve(false);
          }
        });
      }
    });
  }
}
