import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Token } from '../models/token';
import { environment as env} from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class TokenService {
  baseUrl = `${env.apiHost}:${env.apiPort}/api/token`;
  constructor(private hc: HttpClient) { }

  single(token: string): Observable<Token> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<Token>(`${this.baseUrl}/${token}`, {
      headers: {
        's-t': session
      }
    }).pipe(
			retry(2),
			catchError((error: HttpErrorResponse) => {
				const tk: Token = {
					_id: 'noline',
					deleted: false,
					email: 'none',
					token: 'xyz',
					what: 'LOGIN'
				}
				return of(tk);
			})
		);
  }

  delete(token: string): Observable<boolean> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    const self = this;
    return new Observable(observer => {
      self.hc.delete(`${this.baseUrl}/${token}`, {
        headers : {
          's-t': session
        },
        observe: 'response',
        responseType: 'text'
      }).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          observer.next(true);
        } else {
          observer.error(res.status);
        }
        return {unsubscribe() {}};
      });
    });
  }
}
