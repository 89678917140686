import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'setpass/:token', loadChildren: './setpass/setpass.module#SetpassPageModule' },
  { path: 'set-token', loadChildren: './set-token/set-token.module#SetTokenPageModule' },
  { path: 'set-email', loadChildren: './set-email/set-email.module#SetEmailPageModule' },
  { path: 'logout', loadChildren: './logout/logout.module#LogoutPageModule' },
  { path: 'go-to-course', loadChildren: './go-to-course/go-to-course.module#GoToCoursePageModule' },
  { path: 'choose-book', loadChildren: './choose-book/choose-book.module#ChooseBookPageModule' },
  { path: 'choose-lesson', loadChildren: './choose-lesson/choose-lesson.module#ChooseLessonPageModule' },
  { path: 'start-lesson', loadChildren: './start-lesson/start-lesson.module#StartLessonPageModule' },
  { path: 'choose-wb', loadChildren: './choose-wb/choose-wb.module#ChooseWbPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
